<div class="lpmplatinum-plan-payment-page"
     fxFlex="90"
     fxFlexOffset="5"
     fxLayout="row"
     fxLayoutGap="50px">
  <div class="lpmplatinum-plan-details plan-details"
       fxFlex="60">
    <div *ngIf="planContent">
      <h2>
        {{planContent.title}}
      </h2>
      <a class="choose-plan-button"
         color="warn"
         mat-flat-button
         (click)="backToPlans()">
        View plans
      </a>

      <ul>
        <li *ngFor="let item of planContent.features; let i = index"
            [innerHTML]="item"></li>
      </ul>

      <span *ngIf="planContent.soonFeatures && planContent.soonFeatures.length > 0">
        <h4>
          Soon:
        </h4>
        <ul>
          <li *ngFor="let item of planContent.soonFeatures; let i = index"
              [innerHTML]="item"></li>
        </ul>
      </span>
    </div>
  </div>
  <form autocomplete="off"
        fxFlex="40"
        [formGroup]="completeNuagedxRegistrationForm"
        (ngSubmit)="onSubmit()">
    <!-- Hidden field validCreditCard:boolean -->
    <input #validCreditCard
           name="validCreditCard"
           type="hidden"
           formControlName="validCreditCard">

    <!-- title -->
    <h2>
      Complete the NuageDx Sign Up
    </h2>
    <input name="recurly-token"
           type="hidden"
           data-recurly="token"
           formControlName="recurlyToken">
    <div fxLayout="row"
         fxLayoutGap="15px grid">
      <mat-form-field fxFlex="50">
        <mat-label>
          First Name
        </mat-label>
        <input required
               type="text"
               data-recurly="first_name"
               formControlName="first_name"
               matInput>
        <mat-error *ngIf="
            completeNuagedxRegistrationForm.get('first_name').touched &&
            completeNuagedxRegistrationForm.get('first_name').invalid
          ">
          {{getErrorMessage('first_name')}}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-label>
          Last Name
        </mat-label>
        <input type="text"
               data-recurly="last_name"
               formControlName="last_name"
               matInput>
        <mat-error *ngIf="
            completeNuagedxRegistrationForm.get('last_name').touched &&
            completeNuagedxRegistrationForm.get('last_name').invalid
          ">
          {{getErrorMessage('last_name')}}
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row"
         fxLayoutGap="15px grid">
      <mat-form-field fxFlex="50">
        <mat-label>
          Email
        </mat-label>
        <input autocomplete="new-email"
               name="email"
               required
               type="email"
               formControlName="email"
               matInput>
        <mat-error *ngIf="
            completeNuagedxRegistrationForm.get('email').touched && completeNuagedxRegistrationForm.get('email').invalid
          ">
          {{getErrorMessage('email')}}
        </mat-error>
      </mat-form-field>

      <input type="hidden"
             formControlName="recurlyplan">
      <div fxFlex="50"
           fxLayout="row">
        <mat-form-field fxFlex="65">
          <mat-label>
            Cell Phone Number
          </mat-label>
          <input required
                 type="text"
                 formControlName="phone"
                 mask="(000) 000-0000"
                 matInput>
          <mat-error *ngIf="
              completeNuagedxRegistrationForm.get('phone').touched &&
              completeNuagedxRegistrationForm.get('phone').invalid
            ">
            {{getErrorMessage('phone')}}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="30"
                        fxFlexOffset="5">
          <mat-label>
            Extension
          </mat-label>
          <input type="number"
                 formControlName="extension"
                 matInput>
          <mat-error *ngIf="
              completeNuagedxRegistrationForm.get('extension').touched &&
              completeNuagedxRegistrationForm.get('extension').invalid
            ">
            {{getErrorMessage('extension')}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row"
         fxLayoutGap="15px grid">
      <mat-form-field fxFlex="50">
        <mat-label>
          Company Name
        </mat-label>
        <input type="text"
               formControlName="companyName"
               matInput>
        <mat-error *ngIf="
            completeNuagedxRegistrationForm.get('companyName').touched &&
            completeNuagedxRegistrationForm.get('companyName').invalid
          ">
          {{getErrorMessage('companyName')}}
        </mat-error>
      </mat-form-field>
    </div>

    <div formGroupName="creditCard"
         [ngClass]="{ hidden: currentPlan === 'free' }">
      <div id="recurly-elements">
        <!-- Recurly Elements will be attached here -->
      </div>
      <div *ngIf="showWrongCreditCard"
           class="error-message">
        {{ creditCardError }}
      </div>
      <div fxLayout="row"
           fxLayoutGap="15px grid">
        <div fxFlex="50"
             fxLayout="column">
          <mat-form-field>
            <mat-label>
              Address
            </mat-label>
            <input required
                   type="text"
                   data-recurly="address1"
                   formControlName="address"
                   matInput>
            <mat-error *ngIf="
                completeNuagedxRegistrationForm.get('creditCard').get('address').touched &&
                completeNuagedxRegistrationForm.get('creditCard').get('address').invalid
              ">
              {{ getErrorMessageN(['creditCard', 'address']) }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              Address 2
            </mat-label>
            <input type="text"
                   formControlName="address2"
                   matInput>
            <mat-error *ngIf="
                completeNuagedxRegistrationForm.get('creditCard').get('address2').touched &&
                completeNuagedxRegistrationForm.get('creditCard').get('address2').invalid
              ">
              {{ getErrorMessageN(['creditCard', 'address2']) }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="50"
             fxLayout="row"
             fxLayoutGap="15px grid">
          <mat-form-field fxFlex="33">
            <mat-label>
              City
            </mat-label>
            <input max="2"
                   required
                   type="text"
                   data-recurly="city"
                   formControlName="city"
                   matInput>
            <mat-error *ngIf="
                completeNuagedxRegistrationForm.get('creditCard').get('city').touched &&
                completeNuagedxRegistrationForm.get('creditCard').get('city').invalid
              ">
              {{ getErrorMessageN(['creditCard', 'state']) }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="33">
            <mat-label>
              State
            </mat-label>
            <input max="2"
                   required
                   type="text"
                   data-recurly="state"
                   formControlName="state"
                   mask="AA"
                   matInput>
            <mat-error *ngIf="
                completeNuagedxRegistrationForm.get('creditCard').get('state').touched &&
                completeNuagedxRegistrationForm.get('creditCard').get('state').invalid
              ">
              {{ getErrorMessageN(['creditCard', 'state']) }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="33"
                          fxFlexOffset="6">
            <mat-label>
              Zip
            </mat-label>
            <input max="5"
                   min="3"
                   required
                   type="text"
                   data-recurly="postal_code"
                   formControlName="zip"
                   matInput
                   maxLength="5"
                   minlength="3">
            <mat-error *ngIf="
                completeNuagedxRegistrationForm.get('creditCard').get('zip').touched &&
                completeNuagedxRegistrationForm.get('creditCard').get('zip').invalid
              ">
              {{ getErrorMessageN(['creditCard', 'zip']) }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <div style="font-size: 13px"
           fxLayout="row">
        <!-- Material Form Checkbox -->
        <mat-checkbox color="warn"
                      required
                      formControlName="terms"
                      fxFlex="48">
          I agree to the
          <a href="https://nuagedx.com/terms-and-conditions/"
             target="_blank">
            Terms of Service
          </a>
        </mat-checkbox>
        <!-- Material Form Checkbox -->
        <mat-checkbox color="warn"
                      required
                      formControlName="baa"
                      fxFlex="48"
                      fxFlexOffset="4">
          I agree to the
          <a href="https://nuagedx.com/hipaa-business-agreement-addendum/"
             target="_blank">
            BAA
          </a>
        </mat-checkbox>
      </div>
    </div>
    <div class="form-actions">
      <button color="warn"
              type="submit"
              mat-flat-button
              [disabled]="completeNuagedxRegistrationForm.invalid">
        {{ 'Submit' | uppercase }}
      </button>
    </div>
  </form>
</div>
