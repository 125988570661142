<app-window-title [closable]="true"
                  [title]="'Schedule a meeting'"></app-window-title>
<div *ngIf="!showAddToCalendar">
  <form action>
    <div fxLayout="column">
      <div fxFlex="100">
        <mat-form-field color="warn"
                        appearance="fill"
                        [ngStyle]="{ width: '100%' }">
          <mat-label>
            Topic
          </mat-label>
          <input #topic

                 required
                 matInput

                 [value]="meetingTopic"
                 (change)="meetingTopic = topic.value">
        </mat-form-field>
      </div>

      <div fxFlex="100">
        <mat-form-field color="warn"
                        appearance="fill"
                        [ngStyle]="{ width: '100%' }">
          <mat-label>
            What is your agenda?
          </mat-label>
          <textarea #agenda

                    placeholder="Ex. Review cases with associates."
                    rows="3"
                    matInput

                    [value]="meetingAgenda"
                    (change)="meetingAgenda = agenda.value"></textarea>
        </mat-form-field>
      </div>
    </div>
    <br>

    <div fxLayout="row"
         fxLayoutAlign="space-between start">
      <div fxFlex="25"
           fxLayout="column">
        <mat-form-field color="warn"
                        appearance="fill"
                        [ngStyle]="{ width: '100%' }">
          <mat-label>
            Choose a date
          </mat-label>
          <input #date

                 required
                 matInput

                 [matDatepicker]="pickerFrom"
                 [min]="minDate"
                 [value]="meetingDate"

                 (dateChange)="addEvent('change', $event)"
                 (dateInput)="addEvent('input', $event)">
          <mat-datepicker-toggle matSuffix
                                 [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <!-- TIME -->
        <mat-form-field color="warn"
                        appearance="fill"
                        [ngStyle]="{ width: '100%' }">
          <mat-label>
            Choose a time
          </mat-label>
          <input #time

                 readonly
                 required
                 matInput

                 [format]="12"
                 [minutesGap]="5"
                 [ngxTimepicker]="picker"
                 [value]="meetingTime">
          <mat-icon [ngClass]="'timepicker-icon'">
            schedule
          </mat-icon>
          <ngx-material-timepicker #picker
                                   [ngClass]="'timepicker-control'"
                                   (timeChanged)="meetingTime = $event">
          </ngx-material-timepicker>
        </mat-form-field>

        <!-- DURATION -->
        <mat-form-field color="warn"
                        appearance="fill"
                        [ngStyle]="{ width: '100%' }">
          <mat-label>
            Duration (minutes)
          </mat-label>
          <mat-select #duration
                      required
                      [(value)]="meetingDuration"
                      (change)="meetingDuration = duration.value">
            <mat-option [value]="15">
              15
            </mat-option>
            <mat-option [value]="30">
              30
            </mat-option>
            <mat-option [value]="45">
              45
            </mat-option>
            <mat-option [value]="60">
              60
            </mat-option>
            <mat-option [value]="120">
              120
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- SELECT A TIMEZONE -->
        <mat-form-field appearance="fill">
          <mat-label>
            Select a timezone:
          </mat-label>
          <mat-select #timezone
                      required
                      [(value)]="meetingTimezone"
                      (change)="meetingTimezone = timezone.value">
            <mat-option *ngFor="let item of timezones"
                        [value]="item.value">
              {{ item.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- ATTENDEES -->
      <div class="attendees"
           fxFlex="73"
           fxFlexOffset="2"
           fxLayout="column"
           fxLayoutAlign="space-between start">
        <div>
          <mat-label>
            <b>
              Choose consultants to be invited
            </b>
          </mat-label>
          <mat-selection-list #guests
                              class="consultants-list"
                              [(value)]="selectedOptions"
                              (selectionChange)="handleGuestsSelection(guests.selectedOptions)">
            <mat-list-option *ngFor="let item of consultantsList"
                             [ngClass]="'guest-item'"
                             [selected]="item.checked"
                             [value]="item.email">
              {{ item.name }} {{ item.jobDescription ? '(' + item.jobDescription + ') -' : '-' }}
              {{ item.email }}
            </mat-list-option>
          </mat-selection-list>
        </div>

        <div>
          <mat-label>
            <b>
              Add custom guests
            </b>
          </mat-label>
          <div fxLayout="field">
            <div fxFlex="80%">
              <form [formGroup]="guestsForm">
                <mat-form-field [ngStyle]="{ width: '100%' }">
                  <mat-label>
                    Guest email
                  </mat-label>
                  <input #customGuest

                         placeholder="Ex. pat@example.com"
                         type="email"

                         formControlName="emailFormControl"
                         matInput
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete"
                                    autoActiveFirstOption>
                    <mat-optgroup *ngFor="let group of userGroupOptions | async"
                                  [label]="group.role">
                      <mat-option *ngFor="let item of group.emails"
                                  [value]="item.email">
                        {{ getItemFullName(item) }}
                      </mat-option>
                    </mat-optgroup>
                  </mat-autocomplete>
                  <mat-error *ngIf="
                      guestsForm.get('emailFormControl').hasError('email') &&
                      !guestsForm.get('emailFormControl').hasError('required')
                    ">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="guestsForm.get('emailFormControl').hasError('required')">
                    Email is
                    <strong>
                      required
                    </strong>
                  </mat-error>
                </mat-form-field>
              </form>
            </div>
            <div fxFlex="20%">
              <button mat-mini-fab
                      (click)="addCustomGuest(customGuest.value); customGuest.value = ''">
                <mat-icon>
                  add
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <br>

  <span *ngIf="validationMessage !== ''"
        [ngClass]="'error-message'">
    {{ validationMessage }}
  </span>

  <button color="warn"
          mat-flat-button
          (click)="handleCreateMeeting()">
    <mat-icon>
      forward_to_inbox
    </mat-icon>
    &nbsp; SCHEDULE AND INVITE
  </button>
</div>

<p *ngIf="showAddToCalendar">
  {{message}}
</p>

<p *ngIf="showAddToCalendar"
   [innerHTML]="nextActionMessage"></p>
