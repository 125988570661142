import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  checkAndRedirectQaSite() {
    if (window.location.hostname === 'nuagedx-qa.web.app') {
      window.location.href = 'https://qa.nuagedx.com';
    }
  }
  constructor(
    private router: Router,
    private sessionStorage_$: SessionStorageService,
  ) {}

  redirectToCompleteClioRegistration() {
    this.router.navigate(['/', 'completeclioregistration']);
  }

  redirectToCompleteNuagedxRegistration(plan?: string) {
    const registrationFormUrl = '/completenuagedxregistration';
    if (plan) {
      this.router.navigate([registrationFormUrl], { queryParams: { plan: plan } });
    } else {
      this.router.navigate([registrationFormUrl]);
    }
  }

  goToHome(error: string) {
    this.router.navigate(['/'], { queryParams: { error } });
  }

  goToLogin(params?) {
    const eu = this.sessionStorage_$.getAddToClioEU();
    if (eu) this.router.navigate(['/login_eu'], params ? params : {});
    else this.router.navigate(['/login'], params ? params : {});
  }

  redirectToLoginClio(queryParams?) {
    console.log('redirectToLoginClio: ', queryParams);
    const eu = this.sessionStorage_$.getAddToClioEU();
    if (eu) this.router.navigate(['/login_eu/clio'], queryParams ? queryParams : null);
    else this.router.navigate(['/login/clio'], queryParams ? queryParams : null);
  }

  goToLoginClio(eu = false) {
    if (eu) this.router.navigate(['/login_eu/clio']);
    else this.router.navigate(['/login/clio']);
  }
}
