import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, NO_ERRORS_SCHEMA, Provider } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MSAL_INSTANCE, MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import Raygun from 'raygun4js';
import { environment } from './../environments/environment';
import { ActiveConsultantComponent } from './active-consultant/active-consultant.component';
import { AddContactComponent } from './add-contact/add-contact.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RaygunErrorHandler } from './app.raygun.setup';
import { AuditLogViewerComponent } from './audit-log-viewer/audit-log-viewer.component';
import { ActiveConsultantsComponent } from './components/active-consultants/active-consultants.component';
import { AddAttachmentsComponent } from './components/add-attachments/add-attachments.component';
import { AddCommentsComponent } from './components/add-comments/add-comments.component';
import { AllusersComponent } from './components/allusers/allusers.component';
import { AnnuallyMonthlySwitchComponent } from './components/annually-monthly-switch/annually-monthly-switch.component';
import { AttorneysLawFirmsComponent } from './components/attorneys-law-firms/attorneys-law-firms.component';
import { AuditLogFilterByUserComponent } from './components/audit-log-filter-by-user/audit-log-filter-by-user.component';
import { AvailableConsultantsComponent } from './components/available-consultants/available-consultants.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ChooseTargetDirectoryComponent } from './components/choose-target-directory/choose-target-directory.component';
import { ClientListItemComponent } from './components/client-list-item/client-list-item.component';
import { ClientMatterDataInfoComponent } from './components/client-matter-data-info/client-matter-data-info.component';
import { ClientMatterInfoComponent } from './components/client-matter-info/client-matter-info.component';
import { ClientProfileComponent } from './components/clientprofile/clientprofile.component';
import { ClioAccessTokenDialogComponent } from './components/clio/clio-access-token-dialog/clio-access-token-dialog.component';
import { ClioImportFilesFromMattersComponent } from './components/clio/clio-import-files-from-matters/clio-import-files-from-matters.component';
import { ClioAuthComponent } from './components/clio/ClioAuth/ClioAuth.component';
import { ClioListDocumentsComponent } from './components/clio/ClioListDocuments/ClioListDocuments.component';
import { ClioListMattersComponent } from './components/clio/ClioListMatters/ClioListMatters.component';
import { CompleteClioRegistrationComponent } from './components/clio/complete-clio-registration/complete-clio-registration.component';
import { CompleteNuagedxRegistrationComponent } from './components/clio/complete-nuagedx-registration/complete-nuagedx-registration.component';
import { HandleClioCustomActionsComponent } from './components/clio/handle-clio-custom-actions/handle-clio-custom-actions.component';
import { ComparePlansColumnsComponent } from './components/compare-plans-columns/compare-plans-columns.component';
import { ComparePlansTableComponent } from './components/compare-plans-table/compare-plans-table.component';
import { ConsultantByClientComponent } from './components/consultant-by-client/consultant-by-client.component';
import { CreateCuaUserComponent } from './components/create-cua-user/create-cua-user.component';
import { CreateadminComponent } from './components/createadmin/createadmincomponent';
import { CreateclientComponent } from './components/createclient/createclient.component';
import { CreatepatientComponent } from './components/createpatient/createpatient.component';
import { CreateuserComponent } from './components/createuser/createuser.component';
import { CustomFoldersComponent } from './components/custom-folders/custom-folders.component';
import { EditFileDialogComponent } from './components/edit-file-dialog/edit-file-dialog.component';
import { EditNoteComponent } from './components/edit-note/edit-note.component';
import { FgpasswordComponent } from './components/fgpassword/fgpassword.component';
import { FileCommentsAttachmentsListComponent } from './components/file-comments-attachments-list/file-comments-attachments-list.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ImportAppsComponent } from './components/import-apps/import-apps.component';
import { InfoWindowComponent } from './components/info-window/info-window.component';
import { JsonToTableComponent } from './components/json-to-table/json-to-table.component';
import { MaintenancePageComponent } from './components/maintenance/maintenance.page';
import { MedicalStudiesListComponent } from './components/medical-studies-list/medical-studies-list.component';
import { MeetingsPageComponent } from './components/meetings-page/meetings-page.component';
import { NewFolderComponent } from './components/new-folder/new-folder.component';
import { OtherPlansDetailsComponent } from './components/other-plans-details/other-plans-details.component';
import { PlanDetailsComponent } from './components/plan-details/plan-details.component';
import { PlansDataComponent } from './components/plans-data/plans-data.component';
import { PracticePantherAuthComponent } from './components/practicepanther/PracticePantherAuth/PracticePantherAuth.component';
import { PracticePantherListDocumentsComponent } from './components/practicepanther/PracticePantherListDocuments/PracticePantherListDocuments.component';
import { PracticePantherListMattersComponent } from './components/practicepanther/PracticePantherListMatters/PracticePantherListMatters.component';
import { PricingTableHeaderComponent } from './components/pricing-table-header/pricing-table-header.component';
import { PricingTableMobileComponent } from './components/pricing-table-mobile/pricing-table-mobile.component';
import { PlansButtonsComponent } from './components/pricing/plans-buttons/plans-buttons.component';
import { ReportComponent } from './components/report/report.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SearchFilesComponent } from './components/search-files/search-files.component';
import { SearchComponent } from './components/search/search.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { ShowUserInfoComponent } from './components/show-user-info/show-user-info.component';
import { SigninComponent } from './components/signin/signin.component';
import { SysadminComponent } from './components/sysadmin/sysadmin.component';
import { AllNotesDialogComponent } from './components/ui/all-notes-dialog/all-notes-dialog.component';
import { BlueAsteriskComponent } from './components/ui/blue-asterisk/blue-asterisk.component';
import { CheckImageComponent } from './components/ui/check-image/check-image.component';
import { ChoosePlanButtonComponent } from './components/ui/choose-plan-button/choose-plan-button.component';
import { ClientProfileInfoBoxComponent } from './components/ui/client-profile-info-box/client-profile-info-box.component';
import { ClientProfileToolbarComponent } from './components/ui/client-profile-toolbar/client-profile-toolbar.component';
import { ClientProfileTopButtonsComponent } from './components/ui/client-profile-top-buttons/client-profile-top-buttons.component';
import { ConfirmationDialogComponent } from './components/ui/confirmation-dialog/confirmation-dialog.component';
import { DocViewerNoDataComponent } from './components/ui/doc-viewer-no-data/doc-viewer-no-data.component';
import { DocViewerComponent } from './components/ui/doc-viewer/doc-viewer.component';
import { DragAndDropUploadComponent } from './components/ui/drag-and-drop-upload/drag-and-drop-upload.component';
import { FilesComparisonComponent } from './components/ui/files-comparison/files-comparison.component';
import { FolderSelectorComponent } from './components/ui/folder-selector/folder-selector.component';
import { GoToDownloadLocationComponent } from './components/ui/go-to-download-location/go-to-download-location.component';
import { HowToBurnADiscComponent } from './components/ui/how-to-burn-a-disc/how-to-burn-a-disc.component';
import { HowToBurnADiscStepsComponent } from './components/ui/how-to-burn-adisc-steps/how-to-burn-adisc-steps.component';
import { InputFileComponent } from './components/ui/inputfile/inputfile.component';
import { NoteDialogComponent } from './components/ui/note-dialog/note-dialog.component';
import { OptionChooserComponent } from './components/ui/optionChooser/optionChooser.component';
import { ShowStudiesDialogComponent } from './components/ui/show-studies-dialog/show-studies-dialog.component';
import { SimpleListSelectionComponent } from './components/ui/simple-list-selection/simple-list-selection.component';
import { SimpleMessageWindowComponent } from './components/ui/simple-message-window/simple-message-window.component';
import { UploadDialogComponent } from './components/ui/uploaddialog/uploaddialog.component';
import { WindowTitleComponent } from './components/ui/window-title/window-title.component';
import { UpdateUserPasswordComponent } from './components/update-user-password/update-user-password.component';
import { UpgradePlanComponent } from './components/upgrade-plan/upgrade-plan.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { ViewNoteComponent } from './components/view-note/view-note.component';
import { VOBInfoWindowComponent } from './components/vob-info-window/vob-info-window.component';
import { CreateZoomMeetingFormComponent } from './create-zoom-meeting-form/create-zoom-meeting-form.component';
import { DefaultCalendarSetUpComponent } from './default-calendar-set-up/default-calendar-set-up.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { EditClientFormComponent } from './edit-client-form/edit-client-form.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OAuthSettings } from './oauth/microsoft.oauth';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { PricingClioComponent } from './pricing-clio/pricing-clio.component';
import { ProgressLoaderComponent } from './progress-loader/progress-loader.component';
import { PromptUser2FACodeComponent } from './prompt-user2-facode/prompt-user2-facode.component';
import { SelectOwnerComponent } from './select-owner/select-owner.component';
import { AuthService } from './services/auth.service';
import { ReportService } from './services/report.service';
import { SearchService } from './services/search.service';
import { TwoFactorAuthRequestDialogComponent } from './two-factor-auth-request/two-factor-auth-request.dialog';
import { TwoFactorAuthSetupDialogComponent } from './two-factor-auth-setup-dialog-component/two-factor-auth-setup-dialog.component';
import { TwoFactorSignInModalComponent } from './two-factor-sign-in-modal/two-factor-sign-in-modal.component';

// <MSALFactorySnippet>
let msalInstance: IPublicClientApplication | undefined;

const isProduction = window.location.hostname === 'app.nuagedx.com';
console.log('isProduction: ', isProduction);

const providers: Provider[] = [
  AuthService,
  SearchService,
  ReportService,
  { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA, useValue: [] },
  { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
  MsalService,
];

export function setupRaygun() {
  this.maintenance = environment.maintenance;
  return () => {
    if (window.location.hostname !== 'app.nuagedx.com') {
      console.log('Raygun disabled for localhost');
      return;
    }

    Raygun('apiKey', 'Q8ZXvU1HZ3ytyO6RVdr85A');
    Raygun('setVersion', environment.config.prodVersion);
    Raygun('enableCrashReporting', true);
    Raygun('enablePulse', true);
    Raygun('enableRealUserMonitoring', true);

    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        Raygun('options', { clientIp: data.ip });
      })
      .catch(error => console.error('Error:', error));
  };
}

export function MSALInstanceFactory(): IPublicClientApplication {
  msalInstance =
    msalInstance ??
    new PublicClientApplication({
      auth: {
        clientId: OAuthSettings.appId,
        redirectUri: OAuthSettings.redirectUri,
      },
    });
  return msalInstance;
}

@NgModule({
  declarations: [
    GoToDownloadLocationComponent,
    HowToBurnADiscComponent,
    HowToBurnADiscStepsComponent,
    ClientProfileInfoBoxComponent,
    ClientProfileToolbarComponent,
    ClientProfileTopButtonsComponent,
    PlansDataComponent,
    PricingTableHeaderComponent,
    PricingTableMobileComponent,
    OtherPlansDetailsComponent,
    PlanDetailsComponent,
    AutoFocusDirective,
    AppComponent,
    SelectOwnerComponent,
    SigninComponent,
    FgpasswordComponent,
    CreateclientComponent,
    CreateuserComponent,
    CreateadminComponent,
    FooterComponent,
    HeaderComponent,
    CreatepatientComponent,
    SearchComponent,
    ClientProfileComponent,
    FolderSelectorComponent,
    BreadcrumbComponent,
    AllusersComponent,
    ReportComponent,
    ConfirmationDialogComponent,
    UpdateUserPasswordComponent,
    InputFileComponent,
    UploadDialogComponent,
    NewFolderComponent,
    NoteDialogComponent,
    AllNotesDialogComponent,
    ShowStudiesDialogComponent,
    DragAndDropUploadComponent,
    ClioListDocumentsComponent,
    ClioAccessTokenDialogComponent,
    PracticePantherAuthComponent,
    PracticePantherListDocumentsComponent,
    PracticePantherListMattersComponent,
    MeetingsPageComponent,
    UploadProgressComponent,
    EditFileDialogComponent,
    ConsultantByClientComponent,
    AvailableConsultantsComponent,
    ActiveConsultantsComponent,
    InfoWindowComponent,
    VOBInfoWindowComponent,
    MaintenancePageComponent,
    EditNoteComponent,
    ChooseTargetDirectoryComponent,
    CustomFoldersComponent,
    SearchFilesComponent,
    DefaultCalendarSetUpComponent,
    CreateZoomMeetingFormComponent,
    HomePageComponent,
    LoginPageComponent,
    LoaderComponent,
    ProgressLoaderComponent,
    EditClientFormComponent,
    EditProfileComponent,
    TwoFactorAuthRequestDialogComponent,
    TwoFactorAuthSetupDialogComponent,
    PromptUser2FACodeComponent,
    TwoFactorSignInModalComponent,
    SimpleMessageWindowComponent,
    OptionChooserComponent,
    UpgradePlanComponent,
    ClioAuthComponent,
    ClioListMattersComponent,
    ImportAppsComponent,
    SessionTimeoutComponent,
    SafeHtmlPipe,
    AuditLogViewerComponent,
    AuditLogFilterByUserComponent,
    JsonToTableComponent,
    ShowUserInfoComponent,
    ResetPasswordComponent,
    SysadminComponent,
    AddContactComponent,
    WindowTitleComponent,
    ClioImportFilesFromMattersComponent,
    SimpleListSelectionComponent,
    HandleClioCustomActionsComponent,
    DocViewerComponent,
    DocViewerNoDataComponent,
    FilesComparisonComponent,
    ClientMatterInfoComponent,
    MedicalStudiesListComponent,
    CompleteClioRegistrationComponent,
    ClientListItemComponent,
    CreateCuaUserComponent,
    PricingClioComponent,
    ViewNoteComponent,
    AddAttachmentsComponent,
    AddCommentsComponent,
    FileViewerComponent,
    FileCommentsAttachmentsListComponent,
    ClientMatterDataInfoComponent,
    AttorneysLawFirmsComponent,
    NotificationsComponent,
    ActiveConsultantComponent,
    CheckImageComponent,
    BlueAsteriskComponent,
    ChoosePlanButtonComponent,
    PlansButtonsComponent,
    AnnuallyMonthlySwitchComponent,
    CompleteNuagedxRegistrationComponent,
    ComparePlansTableComponent,
    ComparePlansColumnsComponent,
  ],
  imports: [
    BrowserModule,
    MatAutocompleteModule,
    FormsModule,
    MatTreeModule,
    AppRoutingModule,
    CommonModule,
    FlexLayoutModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatMenuModule,
    MatPasswordStrengthModule,
    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatChipsModule,
    MatSelectModule,
    MatExpansionModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    NgxDocViewerModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatDialogModule,
    HttpClientModule,
    MatSortModule,
    MatRadioModule,
    MsalModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AuthService,
    SearchService,
    ReportService,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    MsalService,
    ...(isProduction
      ? [
          {
            provide: ErrorHandler,
            useClass: RaygunErrorHandler,
          },
          {
            provide: APP_INITIALIZER,
            useFactory: setupRaygun,
            multi: true,
          },
        ]
      : []),
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ClientProfileComponent,
    FolderSelectorComponent,
    BreadcrumbComponent,
    UploadDialogComponent,
    ConfirmationDialogComponent,
    NoteDialogComponent,
    AllNotesDialogComponent,
    ShowStudiesDialogComponent,
    EditFileDialogComponent,
    ConsultantByClientComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
